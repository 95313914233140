import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import "./css/LoginPage.css";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LanguageSelector } from "./LanguageSelector";
import { getApiBaseUrl, getAppUrl, getStartUrl } from "./UrlUtil";

// Axios client setup
const axiosClient = axios.create({
  baseURL: getApiBaseUrl(),
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

function LoginApp() {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [updateMessage, setUpdateMessage] = useState("");
  const [updateType, setUpdateType] = useState("info");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const isMobileScreen = useMediaQuery("(max-width:700px)");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [data, setData] = useState({
    email: "",
    password: "",
    isSubmitting: false,
    errorMessage: null,
  });

  useEffect(() => {
    document.title = "Partihanteraren • Logga in";
  }, []);

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  useEffect(() => {
    if (emailRef.current && emailRef.current.value) {
      emailRef.current.focus();
      emailRef.current.blur();
    }
    if (passwordRef.current && passwordRef.current.value) {
      passwordRef.current.focus();
      passwordRef.current.blur();
    }
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
    });

    try {
      const response = await axiosClient.post("/auth/login", {
        email: data.email,
        password: data.password,
      });
      if (response.data.success) {
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: null,
        });

        setLoading(false);

        setUpdateMessage("Inloggningen lyckades.");
        setUpdateType("success");
        setSnackbarOpen(true);
        window.location.href = getAppUrl();
      }
    } catch (error) {
      console.error("Login error:", error);
      const serverErrorMessage =
        error.response?.data?.errorMessage ||
        "Inloggningen misslyckades. Vänligen kontrollera dina uppgifter och försök igen.";
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: serverErrorMessage,
      });
      setLoading(false);
      setUpdateMessage(serverErrorMessage);
      setUpdateType("error");
      setSnackbarOpen(true);
    }
  };

  return (
    <div className="login-root">
      <div className="login-left">
        {!isMobileScreen && (
          <Box className="logo-container">
            <a href={getStartUrl()}>
              <img
                src="https://storage.googleapis.com/pifa.se/partihandel.se_row.png"
                alt="Logo"
                className="logoimg"
              />
            </a>
          </Box>
        )}

        <Card className="login-card-mobile">
          <CardContent>
            {isMobileScreen ? (
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <a href={getStartUrl()}>
                  <img
                    src="https://storage.googleapis.com/pifa.se/partihandel.se_row.png"
                    alt="Logo"
                    className="logoimg"
                  />
                </a>
                <Box sx={{ marginTop: "-10px" }}>
                  <LanguageSelector color="black" />
                </Box>
              </Box>
            ) : (
              <Typography variant="h4" component="h1" gutterBottom>
                {t("login.welcomeback")}
              </Typography>
            )}

            <form onSubmit={handleSubmit}>
              {loading ? (
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{ padding: "3rem", textAlign: "center" }}
                  >
                    <CircularProgress style={{ margin: "2rem auto" }} />
                  </Grid>
                </Grid>
              ) : (
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl fullWidth margin="dense">
                      <TextField
                        fullWidth
                        margin="dense"
                        id="outlined-multiline-flexible"
                        label={t("login.username")}
                        variant="outlined"
                        name="email"
                        type="text"
                        value={data.email}
                        onChange={handleInputChange}
                        autoFocus="true"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth margin="dense">
                      <TextField
                        fullWidth
                        margin="dense"
                        id="outlined-multiline-flexible"
                        label={t("login.password")}
                        variant="outlined"
                        name="password"
                        /*type="password"*/
                        type={showPassword ? "text" : "password"}
                        value={data.password}
                        onChange={handleInputChange}
                        autoFocus="bool"
                        InputProps={{
                          // <-- This is where the toggle button is added.
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth margin="normal">
                      <Button
                        className="login-button"
                        disabled={data.isSubmitting}
                        size="large"
                        style={{
                          background: "#365F62",
                          padding: "15px",
                          textTransform: "none",
                          color: "white",
                          borderRadius: "8px",
                          transition: "background-color 0.3s",
                        }}
                        type="submit"
                        variant="contained"
                        onMouseOver={(e) => {
                          e.target.style.backgroundColor = "#2a4b50";
                        }}
                        onMouseOut={(e) => {
                          e.target.style.backgroundColor = "#365F62";
                        }}
                      >
                        {t("login.login")}
                      </Button>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            </form>
          </CardContent>
        </Card>
        {isMobileScreen && (
          <Box
            className="social-media"
            sx={{
              position: "fixed",
              bottom: 0,
              width: "100vw",
              padding: "10px",
            }}
          >
            <a
              href="https://instagram.com/partihandel.se"
              style={{ color: "white" }}
            >
              <InstagramIcon sx={{ fontSize: "2.5rem" }} />
            </a>
            <a
              href="https://linkedin.com/company/partihanteraren"
              style={{ color: "white" }}
            >
              <LinkedInIcon sx={{ fontSize: "2.5rem" }} />
            </a>
            <a
              href="https://wa.me/+46737133797?text='_'"
              style={{ color: "white" }}
            >
              <WhatsAppIcon sx={{ fontSize: "2.5rem" }} />
            </a>
            <a href="https://tiktok.com/@partihandel">
              <Tooltip title="tiktok.com/@partihandel">
                <svg
                  fill="#ffffff"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 50 50"
                  width="2.5rem"
                  height="2.5rem"
                >
                  <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
                </svg>
              </Tooltip>
            </a>
          </Box>
        )}

        <div
          className="snackbar-container-mobile"
          style={{ marginTop: "2rem" }}
        >
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity={updateType}
              sx={{ width: "100%" }}
            >
              {updateMessage}
            </Alert>
          </Snackbar>
        </div>
      </div>

      <div className="login-right">
        <Card className="login-card">
          <CardContent>
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <Typography variant="h5" component="h1" gutterBottom>
                {t("login.welcomeback")}
              </Typography>
              <Box sx={{ marginTop: "-10px" }}>
                <LanguageSelector color="black" />
              </Box>
            </Box>

            <form onSubmit={handleSubmit}>
              {loading ? (
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{ padding: "3rem", textAlign: "center" }}
                  >
                    <CircularProgress style={{ margin: "2rem auto" }} />
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <FormControl fullWidth margin="dense">
                      <TextField
                        fullWidth
                        margin="dense"
                        id="outlined-multiline-flexible"
                        label={t("login.username")}
                        variant="outlined"
                        name="email"
                        type="text"
                        value={data.email}
                        onChange={handleInputChange}
                        autoFocus="true"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth margin="dense">
                      <TextField
                        fullWidth
                        margin="dense"
                        id="outlined-multiline-flexible"
                        label={t("login.password")}
                        variant="outlined"
                        name="password"
                        /*type="password"*/
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          // <-- toggle button visibility.
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        value={data.password}
                        onChange={handleInputChange}
                        autoFocus="bool"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth margin="normal">
                      <Button
                        className="login-button"
                        disabled={data.isSubmitting}
                        size="large"
                        style={{
                          background: "#365F62",
                          padding: "15px",
                          textTransform: "none",
                          color: "white",
                          borderRadius: "8px",
                          transition: "background-color 0.3s",
                        }}
                        type="submit"
                        variant="contained"
                        onMouseOver={(e) => {
                          e.target.style.backgroundColor = "#2a4b50";
                        }}
                        onMouseOut={(e) => {
                          e.target.style.backgroundColor = "#365F62";
                        }}
                      >
                        {t("login.login")}
                      </Button>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            </form>
          </CardContent>
        </Card>

        <Box className="social-media">
          <a
            href="https://instagram.com/partihandel.se"
            style={{ color: "white" }}
          >
            <InstagramIcon />
          </a>
          <a
            href="https://linkedin.com/company/partihanteraren"
            style={{ color: "white" }}
          >
            <LinkedInIcon />
          </a>
          <a
            href="https://wa.me/+46737133797?text='_'"
            style={{ color: "white" }}
          >
            <WhatsAppIcon />
          </a>
          <a href="https://tiktok.com/@partihandel">
            <Tooltip title="tiktok.com/@partihandel">
              <svg
                fill="#ffffff"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 50 50"
                width="22px"
                height="22px"
              >
                <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
              </svg>
            </Tooltip>
          </a>
        </Box>

        <div className="snackbar-container-desktop">
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity={updateType}
              sx={{ width: "100%" }}
            >
              {updateMessage}
            </Alert>
          </Snackbar>
        </div>
      </div>
    </div>
  );
}

export default LoginApp;
